<template>
  <div class="content">
    <div class="wrapper" v-if="!isIE">
      <div class="main-panel">
        <div class="container-fluid">
          <div class="card" v-if="!running">
            <div class="card-header">
              <div class="card-header">
                <h4 class="card-title">Uzstādījumi</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <label class="col-sm-3 control-label">Darbības</label>
                  <div class="form-check checkbox-inline">
                    <label class="form-check-label">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="+"
                        v-model="darbibas"
                      />
                      <span class="form-check-sign"></span>
                      Saskaitīšana
                    </label>
                  </div>
                  <div class="form-check checkbox-inline">
                    <label class="form-check-label">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="-"
                        v-model="darbibas"
                      />
                      <span class="form-check-sign"></span>
                      Atņemšana
                    </label>
                  </div>
                  <div class="form-check checkbox-inline">
                    <label class="form-check-label">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="x"
                        v-model="darbibas"
                      />
                      <span class="form-check-sign"></span>
                      Reizināšana
                    </label>
                  </div>
                  <div class="form-check checkbox-inline">
                    <label class="form-check-label">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=":"
                        v-model="darbibas"
                      />
                      <span class="form-check-sign"></span>
                      Dalīšana
                    </label>
                  </div>
                </div>
              </div>
              <!-- -------------------------------------------- -->
              <div class="row">
                <label class="col-sm-4 control-label">Viens skaitlis no</label>
                <div class="col-sm-3">
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    v-model.number="min_skaitlis1"
                  />
                </div>
                <label class="col-sm-2 control-label">līdz</label>
                <div class="col-sm-3">
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    v-model.number="max_skaitlis1"
                  />
                </div>
              </div>
              <!-- -------------------------------------------- -->
              <div class="row">
                <label class="col-sm-4 control-label">Otrs skaitlis no</label>
                <div class="col-sm-3">
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    v-model.number="min_skaitlis2"
                  />
                </div>
                <label class="col-sm-2 control-label">līdz</label>
                <div class="col-sm-3">
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    v-model.number="max_skaitlis2"
                  />
                </div>
              </div>
              <!-- -------------------------------------------- -->
              <div class="row">
                <label class="col-sm-3 control-label">Laiks</label>
                <div class="col-sm-3" style="padding-top: 10px">
                  <div class="form-check">
                    <label class="form-check-label">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        :value="true"
                        v-model="laiks"
                      />
                      <span class="form-check-sign"></span>
                      Ieslēgts
                    </label>
                  </div>
                </div>
                <label v-if="laiks" class="col-sm-3 control-label"
                  >Sekundes</label
                >
                <div v-if="laiks" class="col-sm-3">
                  <input
                    type="number"
                    class="form-control"
                    v-model.number="timeout"
                  />
                </div>
              </div>
              <div class="row">
                <label class="col-sm-3 control-label">Uzdevumu skaits</label>
                <div class="col-sm-3">
                  <input
                    type="number"
                    class="form-control"
                    v-model.number="skaits"
                  />
                </div>
                <div class="col-sm-3">
                  <button
                    type="button"
                    @click="sakt"
                    class="btn btn-fill btn-info"
                  >
                    Sākt
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- -------------------------------------------- -->
          <div class="card" v-else>
            <div class="card-header">
              <div class="card-header">
                <h4 class="card-title">Uzdevumi</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <audio
                    v-if="this.laiks"
                    ref="beeps"
                    src="/beep.wav"
                    preload="auto"
                  ></audio>
                  <label for="">Raksti atbildi un apstiprini ar "Enter"</label>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6 text-right">
                  <h1>
                    {{ uzdevumi[curno]["sk1"] }}
                    {{ uzdevumi[curno]["darbiba"] }}
                    {{ uzdevumi[curno]["sk2"] }} =
                  </h1>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <h1>
                      <input
                        type="number"
                        class="form-control rezultats"
                        ref="rezultats"
                        v-model.number="rezultats"
                        v-on:keyup.enter="nakamais"
                      />
                    </h1>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 text-center">
                  <paging :pageCount="uzdevumi.length" :value="curno + 1" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 text-center">
                  <button
                    type="button"
                    @click="atcelt"
                    class="btn btn-fill btn-info"
                  >
                    Atcelt
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- -------------------------------------------- -->
          <div class="card" v-if="!running && rezultats">
            <div class="card-header">
              <div class="card-header">
                <h4 class="card-title">Rezultāti</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <h4>
                    {{ rezultats.pareizi }}
                    {{
                      rezultats.pareizi % 10 == 1
                        ? "pareiza atbilde"
                        : "pareizas atbildes"
                    }}
                  </h4>
                  <h4 :key="idx" v-for="(r, idx) in rezultats.kluda">
                    {{ r.sk1 }} {{ r.darbiba }} {{ r.sk2 }} = {{ r.pareizi }}
                    <span v-if="r.rezultats" class="s"
                      >(nevis {{ r.rezultats }})</span
                    >
                  </h4>
                </div>
              </div>
              <div class="row" v-if="rezultats.kluda.length == 0">
                <div class="col-md-12 text-center">
                  <img src="/smile.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="footer">
        <div class="container-fluid">
          <p class="copyright pull-right">
            Žēlabām un/vai ierosinājumiem
            <a href="mailto:toms@gatavs.lv">toMs@gatavs.lv</a>
          </p>
        </div>
      </footer>
    </div>
    <div v-else style="margin: 20px">
      Piedod, tavs pārlūks netiek atbalstīts! Labāk lieto Chrome vai FireFox.
    </div>
  </div>
</template>

<script>
import Paging from "@/components/Pagination";
export default {
  data() {
    return {
      darbibas: ["+", "-", "x", ":"],
      min_skaitlis1: 1,
      max_skaitlis1: 10,
      min_skaitlis2: 1,
      max_skaitlis2: 10,
      skaits: 10,
      laiks: false,
      timeout: 10,
      running: false,
      uzdevumi: [],
      rezultats: null,
      curno: 0,
      cur_timeout: null,
    };
  },
  computed: {
    isIE: function () {
      const userAgent = navigator.userAgent;
      return (
        userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1
      );
    },
  },
  methods: {
    sakt() {
      this.uzdevumi = [];
      this.curno = 0;
      this.rezultats = null;
      let temp = null;
      // vai ir iespējami unikāli piemēri vai nē - nedaudz kombinatorikas
      const sk1_count = Math.abs(this.max_skaitlis1 - this.min_skaitlis1) + 1;
      const sk2_count = Math.abs(this.max_skaitlis2 - this.min_skaitlis2) + 1;
      // iespējamās kombinācijas
      const unique = sk1_count * sk2_count * 2 * this.darbibas.length - Math.min(sk1_count, sk2_count) >=  this.skaits ? true : false;
      //generate uzdevumi
      let meginat=0 // sis nelielai drošibai
      while (this.uzdevumi.length < this.skaits && meginat<1000) {
        meginat += 1
        const uzd = {
          sk1: this.getRandomInt(this.min_skaitlis1, this.max_skaitlis1),
          sk2: this.getRandomInt(this.min_skaitlis2, this.max_skaitlis2),
          darbiba: this.darbibas[
            Math.floor(Math.random() * this.darbibas.length)
          ],
        };
        // samainam skaitļus vietām pēc gadījuma, lai nav visi ar vienādu ciparu
        if (this.getRandomInt(0, 1) > 0) {
          temp = uzd.sk2;
          uzd.sk2 = uzd.sk1;
          uzd.sk1 = temp;
        }
        // dalīšanas gadījums - primais skaitlis ir skaitļu reizinājums
        if (uzd.darbiba == ":") {
          uzd.sk1 = uzd.sk1 * uzd.sk2;
        }
        // atņemšanas gadījums. lielākais skaitlis pa priekšu
        if (uzd.darbiba == "-" && uzd.sk1 < uzd.sk2) {
          temp = uzd.sk1;
          uzd.sk1 = uzd.sk2;
          uzd.sk2 = temp;
        }
        // check for uniq if needed
        if (unique) {
          if (!this.uzdevums_eksiste(uzd)) {this.uzdevumi.push(uzd);}
        } else {
          this.uzdevumi.push(uzd);
        }
      }
      this.running = true;
      if (this.laiks) {
        this.cur_timeout = setTimeout(
          this.laiks_iztecejis,
          this.timeout * 1000
        );
      }
      this.$nextTick(() => {
        this.$refs.rezultats.focus();
      });
    },
    uzdevums_eksiste(uzd)
    {
      for (let i = 0; i < this.uzdevumi.length; i++) {
        if (this.uzdevumi[i].sk1==uzd.sk1 && this.uzdevumi[i].sk2==uzd.sk2 && this.uzdevumi[i].darbiba==uzd.darbiba) {
          return true
        }
      }
      return false
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    laiks_iztecejis() {
      this.$refs.beeps.play();
      this.nakamais();
    },
    nakamais() {
      if (this.cur_timeout) {
        clearTimeout(this.cur_timeout);
      }
      this.uzdevumi[this.curno]["rezultats"] = this.rezultats;
      this.rezultats = null;
      this.curno++;
      // rezultati
      if (this.curno == this.uzdevumi.length) {
        this.rezultats = { pareizi: 0, kluda: [] };
        for (let i = 0; i < this.uzdevumi.length; i++) {
          if (this.uzdevumi[i]["darbiba"] == "+") {
            this.uzdevumi[i]["pareizi"] =
              this.uzdevumi[i]["sk1"] + this.uzdevumi[i]["sk2"];
          } else if (this.uzdevumi[i]["darbiba"] == "-") {
            this.uzdevumi[i]["pareizi"] =
              this.uzdevumi[i]["sk1"] - this.uzdevumi[i]["sk2"];
          } else if (this.uzdevumi[i]["darbiba"] == "x") {
            this.uzdevumi[i]["pareizi"] =
              this.uzdevumi[i]["sk1"] * this.uzdevumi[i]["sk2"];
          } else if (this.uzdevumi[i]["darbiba"] == ":") {
            this.uzdevumi[i]["pareizi"] =
              this.uzdevumi[i]["sk1"] / this.uzdevumi[i]["sk2"];
          }
          if (this.uzdevumi[i]["pareizi"] == this.uzdevumi[i]["rezultats"]) {
            this.rezultats["pareizi"]++;
          } else {
            this.rezultats["kluda"].push(this.uzdevumi[i]);
          }
        }
        this.running = false;
      } else {
        if (this.laiks) {
          this.cur_timeout = setTimeout(
            this.laiks_iztecejis,
            this.timeout * 1000
          );
        }
      }
    },
    atcelt() {
      if (this.cur_timeout) {
        clearTimeout(this.cur_timeout);
      }
      this.running = false;
      this.rezultats = false;
    },
  },
  components: { Paging },
};
</script>

<style scoped>
.main-panel {
  width: 100%;
  padding: 30px;
}
.card {
  max-width: 800px;
  padding-bottom: 30px;
  margin: 10px;
}
.container-fluid {
  display: flex;
  justify-content: center;
}

input.rezultats {
  font-size: 1em;
  height: 60px;
}
.pagination {
  margin-top: 30px;
}
.pagination li {
  margin: 5px;
}
.pagination li a {
  color: #999999 !important;
}
.pagination li.active a {
  color: white !important;
}
.s {
  color: #ccc;
}
h4 {
  margin: 10px;
}
</style>